<template>
    <div class="count-container">
      <div class="txt-color title">{{ title }}</div>
      <div class="txt-color time">{{ timeValue[0] }} ~ {{ timeValue[1] }}</div>
      <div class="count">
        <countTo
          :startVal="Number(startVal)"
          :endVal="Number(endVal)"
          :duration="duration"
        ></countTo>
        <span class="suffix txt-color">{{ suffix }}</span>
      </div>
      <div class="txt-color compare">
        <div>{{ remarks }}</div> 
      </div>
    </div>
  </template>
  <script>
  import countTo from "vue-count-to";
  import { format, subDays } from "date-fns";
  export default {
    name: "count-to-num-one",
    components: { countTo },
    props: {
      title: {
        type: String,
        default: "数据统计"
      },
      time: {
        type: Array,
        default: () => {
          return [
            format(subDays(new Date(), 7), "yyyy-MM-dd"),
            format(new Date(), "yyyy-MM-dd")
          ];
        }
      },
      startVal: {
        type: Number,
        default: 0
      },
      endVal: {
        type: Number,
        default: 1000
      },
      suffix: {
        type: String,
        default: ""
      },
      yearOnYear: {},
      chainRatio: {}
    },
    data() {
      return {
        duration: 3000
      };
    },
    computed: {
      timeValue() {
        let _array = [];
        if (this.time[0] instanceof Date && this.time[1] instanceof Date) {
          _array[0] = format(this.time[0], "yyyy-MM-dd");
          _array[1] = format(this.time[1], "yyyy-MM-dd");
        } else {
          _array[0] = format(new Date(this.time[0]), "yyyy-MM-dd");
          _array[1] = format(new Date(this.time[1]), "yyyy-MM-dd");
        }
        return _array;
      }
    },
    created() {},
    mounted() {},
    methods: {}
  };
  </script>
  <style scoped lang="scss">
  .count-container {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .txt-color {
    color: #6a788a;
  }
  .title {
    font-size: 16px;
  }
  .time {
    font-size: 14px;
  }
  .count {
    font-size: 32px;
  }
  .suffix {
    font-size: 16px;
  }
  .compare,
  .compare div {
    display: flex;
    align-items: center;
  }
  .compare div {
    margin-right: 10px;
  }
  .compare span {
    margin-left: 5px;
    margin-right: 2px;
  }
  .up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #2cca93;
    font-size: 0;
    line-height: 0;
  }
  .down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid #fd6771;
    font-size: 0;
    line-height: 0;
  }
  </style>
  